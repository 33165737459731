
import { createApp } from 'vue'
import ProductSliderApp from '../product-detail/app.vue'

document.addEventListener('turbolinks:load', () => {
  [ProductSliderApp].forEach(function(app) {
    let elementId = app.el.substring(1);
    if (document.getElementById(elementId)) {
      createApp(app).mount("#"+elementId)
    }
  })
})
